import { protoInt64 } from "@bufbuild/protobuf";
import { Associate, DebitNote, Family, FAMILY_TYPE, getClientForCurrenciesService, getClientForGoodsReceiptsService, getClientForOutwardJobsService, getClientForPurchasesOrdersService, getClientForPurchasesReturnsService, getClientForUnitsOfMaterialsService, GoodsReceipt, OutwardJob, OutwardJobFreeIssueMaterial, OutwardJobFreeIssueMaterialReturn, PurchaseEnquiry, PurchaseOrder, PurchasePayment, PurchaseReturn, QCSample, QuotationRequest, QuotationResponse, UnitOfMaterial, VendorInvoice, VendorItem, VendorStream } from "@kernelminds/scailo-sdk";
import { convertAndColorizeBigint, convertAndColorizeBigintInverted, convertBigIntTimestampToDate, convertBigIntTimestampToDateTime, convertCentsToMoney, downloadButtonClass, internationalizeMoney, renderTableWithCSVHeadersAndRows, toTitleCase } from "./utilities";
import * as fetches from "./fetches";
import { colorizeCompletionPercentage, colorizeQCSampleLifecycleStatus, colorizeStandardLifecycleStatus, getLinkForDebitNote, getLinkForGoodsReceipt, getLinkForOutwardJob, getLinkForOutwardJobFreeIssueMaterial, getLinkForOutwardJobFreeIssueMaterialReturn, getLinkForPurchaseOrder, getLinkForPurchaseReturn, getLinkForVendorInvoice, getLinkForVendorStream } from "./ui";
import { getTransport } from "./clients";

/**Denotes a success icon */
export const successIcon = `<i class="text-green-700 text-2xl bx bx-check-double"></i>`;
/**Denotes a failure icon */
export const failureIcon = `<i class="text-red-500 text-2xl bx bx-x"></i>`;

/**Returns the table for purchases orders */
export async function viewPurchasesOrders(purchasesorders: PurchaseOrder[], opts: {
    inventoryMatchClass: string
}) {
    let tableHeader: string[] = [];

    tableHeader = ["Purchase Order Id", "Line Items", "Price", "Created/Approved", "Inventory Progress", "Billing Progress", "Status", "View", "Inventory Match", "Download"];
    const transport = getTransport();
    const purchaseorderClient = getClientForPurchasesOrdersService(transport);
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = purchasesorders.map(async function (purchaseorder) {
        let displayName = purchaseorder.approvalMetadata!.approvedOn > 0 ? purchaseorder.finalRefNumber : purchaseorder.referenceId

        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForPurchaseOrder(purchaseorder)}"><i class="bx bx-door-open"></i></a>`;

        let [currency, inventoryStats, billingStats] = await Promise.all([
            fetches.currency(purchaseorder.currencyId, currenciesClient),
            fetches.purchaseorderInventoryStatistics(purchaseorder.metadata!.uuid, purchaseorderClient),
            fetches.purchaseorderBillingStatistics(purchaseorder.metadata!.uuid, purchaseorderClient),
        ]);

        if (inventoryStats.ordered == protoInt64.zero) {
            inventoryStats.ordered = protoInt64.parse(1);
        }
        if (billingStats.ordered == protoInt64.zero) {
            billingStats.ordered = protoInt64.parse(1);
        }

        const inventoryStatsSpan = colorizeCompletionPercentage(protoInt64.parse(Math.round(parseFloat(String(inventoryStats.received - inventoryStats.returned)) / parseFloat(String(inventoryStats.ordered)) * 100 * 100)));
        const billingStatsSpan = colorizeCompletionPercentage(protoInt64.parse(Math.round(parseFloat(String(billingStats.billed - billingStats.debited)) / parseFloat(String(billingStats.ordered)) * 100 * 100)));

        return [
            displayName,
            (purchaseorder.list.length).toString(),
            `${currency.symbol} ${internationalizeMoney(purchaseorder.totalValue)}`,
            `${convertBigIntTimestampToDate(purchaseorder.metadata!.createdAt)}/${convertBigIntTimestampToDate(purchaseorder.approvalMetadata!.approvedOn)}`,
            `${inventoryStatsSpan.outerHTML}`, `${billingStatsSpan.outerHTML}`,
            colorizeStandardLifecycleStatus(purchaseorder.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${opts.inventoryMatchClass}" data-name="${displayName}" data-uuid="${purchaseorder.metadata?.uuid}"><i class="bx bx-calculator"></i></a>`,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-name="${displayName}" data-uuid="${purchaseorder.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Purchase Orders", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for goods receipts */
export async function viewGoodsReceipts(goodsreceipts: GoodsReceipt[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Goods Receipt ID", "Line Items", "Bill No", "Bill Date", "Invoiced", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const client = getClientForGoodsReceiptsService(transport);

    let tableRowsPromises: Promise<string[]>[] = goodsreceipts.map(async function (goodsreceipt) {
        let displayName = goodsreceipt.approvalMetadata!.approvedOn > 0 ? goodsreceipt.finalRefNumber : goodsreceipt.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForGoodsReceipt(goodsreceipt)}"><i class="bx bx-door-open"></i></a>`;

        let invoiced = await fetches.goodsreceiptBillingStatus(goodsreceipt.metadata!.id, client);
        return [
            displayName,
            (goodsreceipt.list.length).toString(),
            goodsreceipt.vendorBillNo, goodsreceipt.vendorBillDate,
            invoiced ? successIcon : failureIcon,
            `${convertBigIntTimestampToDate(goodsreceipt.metadata!.createdAt)}/${convertBigIntTimestampToDate(goodsreceipt.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(goodsreceipt.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${goodsreceipt.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Goods Receipts", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for vendor invoices */
export async function viewVendorInvoices(vendorinvoices: VendorInvoice[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Vendor Invoice ID", "Line Items", "Bill No", "Bill Date", "Value", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = vendorinvoices.map(async function (vendorinvoice) {
        let displayName = vendorinvoice.approvalMetadata!.approvedOn > 0 ? vendorinvoice.finalRefNumber : vendorinvoice.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForVendorInvoice(vendorinvoice)}"><i class="bx bx-door-open"></i></a>`;

        let [currency] = await Promise.all([
            fetches.currency(vendorinvoice.currencyId, currenciesClient),
        ]);

        return [
            displayName,
            (vendorinvoice.list.length).toString(),
            vendorinvoice.vendorBillNo, vendorinvoice.vendorBillDate,
            `${currency.symbol} ${internationalizeMoney(vendorinvoice.totalValue)}`,

            `${convertBigIntTimestampToDate(vendorinvoice.metadata!.createdAt)}/${convertBigIntTimestampToDate(vendorinvoice.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(vendorinvoice.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${vendorinvoice.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Vendor Invoices", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for debit notes */
export async function viewDebitNotes(debitnotes: DebitNote[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Debit Note ID", "Line Items", "Value", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = debitnotes.map(async function (debitnote) {
        let displayName = debitnote.approvalMetadata!.approvedOn > 0 ? debitnote.finalRefNumber : debitnote.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForDebitNote(debitnote)}"><i class="bx bx-door-open"></i></a>`;

        let [currency] = await Promise.all([
            fetches.currency(debitnote.currencyId, currenciesClient),
        ]);

        return [
            displayName,
            (debitnote.list.length).toString(),
            `${currency.symbol} ${internationalizeMoney(debitnote.totalValue)}`,

            `${convertBigIntTimestampToDate(debitnote.metadata!.createdAt)}/${convertBigIntTimestampToDate(debitnote.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(debitnote.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${debitnote.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Debit Notes", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for purchase returns */
export async function viewPurchaseReturns(purchasereturns: PurchaseReturn[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Purchase Return ID", "Line Items", "Invoiced", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const client = getClientForPurchasesReturnsService(transport);

    let tableRowsPromises: Promise<string[]>[] = purchasereturns.map(async function (purchasereturn) {
        let displayName = purchasereturn.approvalMetadata!.approvedOn > 0 ? purchasereturn.finalRefNumber : purchasereturn.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForPurchaseReturn(purchasereturn)}"><i class="bx bx-door-open"></i></a>`;

        let invoiced = await fetches.purchasereturnBillingStatus(purchasereturn.metadata!.id, client);
        return [
            displayName,
            (purchasereturn.list.length).toString(),
            invoiced ? successIcon : failureIcon,
            `${convertBigIntTimestampToDate(purchasereturn.metadata!.createdAt)}/${convertBigIntTimestampToDate(purchasereturn.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(purchasereturn.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${purchasereturn.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Purchase Returns", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for purchase payments */
export async function viewPurchasePayments(purchasepayments: PurchasePayment[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Payment ID", "Trans Type", "Amount", "Payment Date", "Created/Approved", "Status", "View"];

    const transport = getTransport();
    const currenciesClient = getClientForCurrenciesService(transport);

    let tableRowsPromises: Promise<string[]>[] = purchasepayments.map(async function (purchasepayment) {
        let displayName = purchasepayment.approvalMetadata!.approvedOn > 0 ? purchasepayment.finalRefNumber : purchasepayment.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;

        let [currency] = await Promise.all([
            fetches.currency(purchasepayment.currencyId, currenciesClient),
        ]);

        return [
            displayName,
            toTitleCase(purchasepayment.transactionType),
            `${currency.symbol} ${internationalizeMoney(parseFloat(convertCentsToMoney(purchasepayment.amountNet)))}`,
            convertBigIntTimestampToDate(purchasepayment.paymentTimestamp),
            `${convertBigIntTimestampToDate(purchasepayment.metadata!.createdAt)}/${convertBigIntTimestampToDate(purchasepayment.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(purchasepayment.status).outerHTML,
            individualLink
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Payments", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for outward jobs */
export async function viewOutwardJobs(outwardjobs: OutwardJob[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Outward Job ID", "Line Items (Inward/Outward)", "Ordered/Dispatched/Completable", "Created/Approved", "Status", "View", "Download"];
    const transport = getTransport();
    const client = getClientForOutwardJobsService(transport);

    let tableRowsPromises: Promise<string[]>[] = outwardjobs.map(async function (outwardjob) {
        let displayName = outwardjob.approvalMetadata!.approvedOn > 0 ? outwardjob.finalRefNumber : outwardjob.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForOutwardJob(outwardjob)}"><i class="bx bx-door-open"></i></a>`;

        let [ordered, dispatched, completable] = await Promise.all([
            fetches.outwardjobOrderedStatus(outwardjob.metadata!.id, client),
            fetches.outwardjobDispatchedStatus(outwardjob.metadata!.id, client),
            fetches.outwardjobCompletableStatus(outwardjob.metadata!.id, client),
        ]);

        return [
            displayName,
            (outwardjob.inwardItemsList.length).toString() + '/' + (outwardjob.outwardItemsList.length).toString(),
            
            `${ordered ? successIcon : failureIcon}/${dispatched ? successIcon : failureIcon}/${completable ? successIcon : failureIcon}`,

            `${convertBigIntTimestampToDate(outwardjob.metadata!.createdAt)}/${convertBigIntTimestampToDate(outwardjob.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(outwardjob.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${outwardjob.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Outward Jobs", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for outward jobs free issue materials */
export async function viewOutwardJobsFreeIssueMaterials(outwardjobsfreeissuematerials: OutwardJobFreeIssueMaterial[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Free Issue Material ID", "Line Items", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = outwardjobsfreeissuematerials.map(async function (outwardjobfreeissuematerial) {
        let displayName = outwardjobfreeissuematerial.approvalMetadata!.approvedOn > 0 ? outwardjobfreeissuematerial.finalRefNumber : outwardjobfreeissuematerial.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForOutwardJobFreeIssueMaterial(outwardjobfreeissuematerial)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            (outwardjobfreeissuematerial.list.length).toString(),
            `${convertBigIntTimestampToDate(outwardjobfreeissuematerial.metadata!.createdAt)}/${convertBigIntTimestampToDate(outwardjobfreeissuematerial.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(outwardjobfreeissuematerial.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${outwardjobfreeissuematerial.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Free Issue Materials", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for outward jobs free issue material returns */
export async function viewOutwardJobsFreeIssueMaterialReturns(outwardjobsfreeissuematerialreturns: OutwardJobFreeIssueMaterialReturn[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Free Issue Material Return ID", "Line Items", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = outwardjobsfreeissuematerialreturns.map(async function (outwardjobfreeissuematerialreturn) {
        let displayName = outwardjobfreeissuematerialreturn.approvalMetadata!.approvedOn > 0 ? outwardjobfreeissuematerialreturn.finalRefNumber : outwardjobfreeissuematerialreturn.referenceId
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForOutwardJobFreeIssueMaterialReturn(outwardjobfreeissuematerialreturn)}"><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            (outwardjobfreeissuematerialreturn.list.length).toString(),
            `${convertBigIntTimestampToDate(outwardjobfreeissuematerialreturn.metadata!.createdAt)}/${convertBigIntTimestampToDate(outwardjobfreeissuematerialreturn.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(outwardjobfreeissuematerialreturn.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${outwardjobfreeissuematerialreturn.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Free Issue Material Returns", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for quotation requests */
export async function viewQuotationRequests(quotationrequests: QuotationRequest[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Quotation Request ID", "Last Date", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = quotationrequests.map(async function (quotationrequest) {
        let displayName = quotationrequest.approvalMetadata!.approvedOn > 0 ? quotationrequest.finalRefNumber : quotationrequest.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,
            quotationrequest.lastDate,

            `${convertBigIntTimestampToDate(quotationrequest.metadata!.createdAt)}/${convertBigIntTimestampToDate(quotationrequest.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(quotationrequest.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${quotationrequest.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Quotation Requests", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for quotation responses */
export async function viewQuotationResponses(quotationresponses: QuotationResponse[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Quotation Response ID", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = quotationresponses.map(async function (quotationresponse) {
        let displayName = quotationresponse.approvalMetadata!.approvedOn > 0 ? quotationresponse.finalRefNumber : quotationresponse.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,

            `${convertBigIntTimestampToDate(quotationresponse.metadata!.createdAt)}/${convertBigIntTimestampToDate(quotationresponse.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(quotationresponse.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${quotationresponse.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Quotation Responses", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for purchase enquiries */
export async function viewPurchaseEnquiries(purchaseenquiries: PurchaseEnquiry[]) {
    let tableHeader: string[] = [];

    tableHeader = ["Purchase Enquiry ID", "Created/Approved", "Status", "View", "Download"];

    let tableRowsPromises: Promise<string[]>[] = purchaseenquiries.map(async function (purchaseenquiry) {
        let displayName = purchaseenquiry.approvalMetadata!.approvedOn > 0 ? purchaseenquiry.finalRefNumber : purchaseenquiry.referenceId;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;

        return [
            displayName,

            `${convertBigIntTimestampToDate(purchaseenquiry.metadata!.createdAt)}/${convertBigIntTimestampToDate(purchaseenquiry.approvalMetadata!.approvedOn)}`,
            colorizeStandardLifecycleStatus(purchaseenquiry.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${purchaseenquiry.metadata?.uuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Purchase Enquiries", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for qc samples */
export async function viewQCSamples(qcsamples: QCSample[]) {
    let tableHeader: string[] = [];
    tableHeader = ["Sample Name", "Material", "Created/Approved", "Status", "View", "Download"];

    let familiesList = await fetches.familiesListFromIDs(Array.from(new Set(qcsamples.map(s => s.familyId))));
    let familiesMap: Map<bigint, Family> = new Map();
    familiesList.forEach(fam => {
        familiesMap.set(fam.metadata!.id, fam);
    });
    
    let tableRowsPromises: Promise<string[]>[] = qcsamples.map(async function (qcsample) {
        let displayName = qcsample.name;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;
        let family = familiesMap.get(qcsample.familyId) as Family;

        return [
            displayName,
            `(${family.code}) ${family.name}`,
            `${convertBigIntTimestampToDate(qcsample.metadata!.createdAt)}/${convertBigIntTimestampToDate(qcsample.approvalMetadata!.approvedOn)}`,
            colorizeQCSampleLifecycleStatus(qcsample.status).outerHTML,
            individualLink,
            `<a class="cursor-pointer btn btn-sm btn-outline btn-success ${downloadButtonClass}" data-uuid="${qcsample.metadata?.uuid}" data-family-type="${family.familyType.toString()}" data-inventory-ref-uuid="${qcsample.inventoryItemUuid}"><i class="bx bx-cloud-download"></i></a>`
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "QA Samples", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for associates */
export function viewAssociates(associates: Associate[]) {
    let tableHeader = ["Name", "Phone", "Email", "Created", "View"];
    let tableRows: string[][] = associates.map(associate => {
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;
        return [
            associate.firstName + " " + associate.lastName, associate.workPhone, associate.workEmail, convertBigIntTimestampToDate(associate.metadata!.createdAt), individualLink
        ]
    });
    return renderTableWithCSVHeadersAndRows({ title: "Associates", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for vendor materials */
export async function viewVendorMaterials(materials: VendorItem[]) {
    let tableHeader: string[] = [];
    tableHeader = ["Vendor Material Code", "Code", "Name", "Unit Price", "UoM", "Min/Max Order Qty", "Step", "Approved", "Created/Approved", "View"];

    let familiesList = await fetches.familiesListFromIDs(Array.from(new Set(materials.map(s => s.familyId))));
    let familiesMap: Map<bigint, Family> = new Map();
    familiesList.forEach(fam => {
        familiesMap.set(fam.metadata!.id, fam);
    });
    const transport = getTransport();
    const uomClient = getClientForUnitsOfMaterialsService(transport);
    let uomsList = await Promise.all(Array.from(new Set(materials.map(s => s.uomId))).map(id => {
        return uomClient.viewByID({ id });
    }));
    let uomsMap: Map<bigint, UnitOfMaterial> = new Map();

    uomsList.forEach(uom => {
        uomsMap.set(uom.metadata!.id, uom);
    });
    
    let tableRowsPromises: Promise<string[]>[] = materials.map(async function (material) {
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href=""><i class="bx bx-door-open"></i></a>`;
        let family = familiesMap.get(material.familyId) as Family;
        let uom = uomsMap.get(material.uomId) as UnitOfMaterial;

        return [
            material.vendorFamilyCode,
            family.code,
            family.name,
            convertCentsToMoney(material.price),
            `(${uom.symbol}) ${uom.name}`,

            `${convertCentsToMoney(material.minOrderQty)}/${convertCentsToMoney(material.maxOrderQty)}`,
            convertCentsToMoney(material.stepInterval),
            material.approvalMetadata!.approvedOn > 0 ? successIcon : failureIcon,
            `${convertBigIntTimestampToDate(material.metadata!.createdAt)}/${convertBigIntTimestampToDate(material.approvalMetadata!.approvedOn)}`,
            individualLink,
        ]
    });

    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title: "Vendor Materials", headers_array: tableHeader, rows_array: tableRows });
}

/**Returns the table for vendor streams */
export async function viewVendorStreams(vendorstreams: VendorStream[], title: string = "Streams") {
    let tableHeader = ["Internal Ref", "Title", "Unread/Total Count", "Created", "Last Updated/By", "View"];
    let tableRowsPromises: Promise<string[]>[] = vendorstreams.map(async function (vendorstream) {
        let displayName = vendorstream.title;
        let individualLink = `<a class="btn btn-sm btn-outline btn-primary" href="${getLinkForVendorStream(vendorstream)}"><i class="bx bx-door-open"></i></a>`;

        return [
            vendorstream.internalRef,
            displayName,

            `${convertAndColorizeBigintInverted(vendorstream.unreadCount)}/${convertAndColorizeBigint(vendorstream.messageCount)}`,
            convertBigIntTimestampToDate(vendorstream.metadata!.createdAt),
            `${convertBigIntTimestampToDateTime(vendorstream.metadata!.modifiedAt)}/${vendorstream.lastMessageBy}`,
            individualLink
        ]
    });
    
    let tableRows = await Promise.all(tableRowsPromises);
    return renderTableWithCSVHeadersAndRows({ title, headers_array: tableHeader, rows_array: tableRows });
}