import { PromiseClient } from "@connectrpc/connect";
import { getTransport } from "./clients";
import { ApprovalMetadata, CurrenciesService, EmployeeMetadata, Family, FormsSectionsService, getClientForFamiliesService, getClientForUsersService, GoodsReceiptsService, IdentifierUUID, OutwardJobsService, PurchasesOrdersService, PurchasesReturnsService, User } from "@kernelminds/scailo-sdk";
import { protoInt64 } from "@bufbuild/protobuf";

/**Returns the currency with the given id */
export async function currency(id: bigint, client: PromiseClient<typeof CurrenciesService>) {
    return client.viewByID({ id });
}

/**Returns the inventory statistics of the purchase order with the given uuid */
export async function purchaseorderInventoryStatistics(uuid: string, client: PromiseClient<typeof PurchasesOrdersService>) {
    return client.viewInventoryStatistics({ uuid });
}

/**Returns the billing statistics of the purchase order with the given uuid */
export async function purchaseorderBillingStatistics(uuid: string, client: PromiseClient<typeof PurchasesOrdersService>) {
    return client.viewBillingStatistics({ uuid });
}

/**Returns the inventory match of the purchase order with the given uuid */
export async function purchaseorderInventoryMatch(uuid: string, client: PromiseClient<typeof PurchasesOrdersService>) {
    return (await client.viewInventoryMatch({ uuid })).list;
}

/**Returns if the goods receipt has been billed */
export async function goodsreceiptBillingStatus(goodsreceiptID: bigint, client: PromiseClient<typeof GoodsReceiptsService>) {
    return (await client.isBilled({ id: goodsreceiptID })).value;
}

/**Returns if the purchase return has been billed */
export async function purchasereturnBillingStatus(goodsreceiptID: bigint, client: PromiseClient<typeof PurchasesReturnsService>) {
    return (await client.isBilled({ id: goodsreceiptID })).value;
}

/**Returns if the outward job can be marked as completed */
export async function outwardjobCompletableStatus(outwardjobID: bigint, client: PromiseClient<typeof OutwardJobsService>): Promise<boolean> {
    return (await client.isCompletable({ id: outwardjobID })).value;
}

/**Returns if the outward job has been dispatched */
export async function outwardjobDispatchedStatus(outwardjobID: bigint, client: PromiseClient<typeof OutwardJobsService>): Promise<boolean> {
    return (await client.isDispatched({ id: outwardjobID })).value;
}

/**Returns if the outward job has been ordered */
export async function outwardjobOrderedStatus(outwardjobID: bigint, client: PromiseClient<typeof OutwardJobsService>): Promise<boolean> {
    return (await client.isOrdered({ id: outwardjobID })).value;
}

/**Returns the list of families from the given IDs */
export async function familiesListFromIDs(ids: bigint[]): Promise<Family[]> {
    let readClient = getClientForFamiliesService(getTransport());
    return (await readClient.viewFromIDs({
        list: ids
    })).list;
}

/**Returns an empty user */
export function emptyUser() {
    return new User({ name: "-", username: "-", code: "-", metadata: new EmployeeMetadata({ id: protoInt64.zero }), approvalMetadata: new ApprovalMetadata({ approvedByUserId: protoInt64.zero, approvedOn: protoInt64.zero, approverRoleId: protoInt64.zero }) });
}

/**Returns the users map from the given list of usernames */
export async function usersMapFromUsernames(usernames: string[]): Promise<Map<string, User>> {
    let readClient = getClientForUsersService(getTransport());
    let usersMap: Map<string, User> = new Map();

    let users = (await readClient.viewFromUsernames({ list: Array.from(new Set(usernames)) })).list;
    users.forEach(user => {
        usersMap.set(user.username, user);
    });
    return usersMap;
}

/**Returns the users map from the given list of IDs */
export async function usersMapFromIDs(userIDs: bigint[]): Promise<Map<bigint, User>> {
    let readClient = getClientForUsersService(getTransport());
    let usersMap: Map<bigint, User> = new Map();

    let users = (await readClient.viewFromIDs({ list: Array.from(new Set(userIDs)) })).list;
    users.forEach(user => {
        usersMap.set(user.metadata?.id!, user);
    });
    // Set for 0
    usersMap.set(protoInt64.zero, emptyUser());
    return usersMap;
}

/**Returns the users map from the given list of UUIDs */
export async function usersMapFromUUIDs(userUUIDs: string[]): Promise<Map<string, User>> {
    let readClient = getClientForUsersService(getTransport());
    let usersMap: Map<string, User> = new Map();

    let users = (await readClient.viewFromUUIDs({ list: Array.from(new Set(userUUIDs)).map(uuid => new IdentifierUUID({ uuid: uuid })) })).list;
    users.forEach(user => {
        usersMap.set(user.metadata?.uuid!, user);
    });
    return usersMap;
}

/**Returns the form section with the given id */
export async function formSection(id: bigint, client: PromiseClient<typeof FormsSectionsService>) {
    return (await client.viewByID({ id }));
}