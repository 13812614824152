import * as ui from "../../ui";
import { _returnInCents, randomId } from "../../utilities";
import { VENDOR_INVOICE_SORT_KEY } from "@kernelminds/scailo-sdk";

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Reference Id", inputType: "text", dataMapper: "referenceId", dataType: "string", value: "", mdColSpan: 6, helpText: "Reference Id is the primary identifier that is created manually. Filter records by the value of this field. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Final Ref Number", inputType: "text", dataMapper: "finalRefNumber", dataType: "string", value: "", mdColSpan: 6, helpText: "Final Ref Number is an automatically assigned sequence number that is created when the record is approved. Filter records by the value of this field. Leave it empty to ignore this field." }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Vendor Bill No", inputType: "text", dataMapper: "vendorBillNo", dataType: "string", value: "", mdColSpan: 4, helpText: "Vendor Bill No is the sequence number that was generated by the vendor. Filter records by the value of this field. Leave it empty to ignore this field." }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Min Total Amount", inputType: "number", dataMapper: "totalValueMin", dataType: "money_in_cents", value: "", mdColSpan: 4, helpText: "Minimum total amount to be considered. If set, all records that have a minimum of the entered amount are returned. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Max Total Amount", inputType: "number", dataMapper: "totalValueMax", dataType: "money_in_cents", value: "", mdColSpan: 4, helpText: "Maximum total amount to be considered. If set, all records that have a maximum of the entered amount are returned. Leave it empty to ignore this field." }));

    return grid
}

function renderBillDatesSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Vendor Bill Dates", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Bill Date", inputType: "date", dataMapper: "vendorBillDateStart", dataType: "string", value: "", mdColSpan: 4, helpText: "Start Bill Date is the minimum bill date to be considered. If set, all records from this bill date are returned. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Bill Date", inputType: "date", dataMapper: "vendorBillDateEnd", dataType: "string", value: "", mdColSpan: 4, helpText: "End Bill Date is the maximum bill date to be considered. If set, all records until this bill date are returned. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Exact Bill Date", inputType: "date", dataMapper: "vendorBillDateExact", dataType: "string", value: "", mdColSpan: 4, helpText: "Exact Bill Date is the exact bill date to be considered. If set, records with this bill date are returned. Leave it empty to ignore this field." }));
    return grid
}

export function _renderPageFilters(context: "Records" | "Insights") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let billDatesSection = renderBillDatesSection();
    let statusFiltersSection = ui.renderStatusFiltersSection();
    let timeFiltersSection = ui.renderTimeFiltersSection();
    let sortFiltersSection = ui.renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "VENDOR_INVOICE_SORT_KEY_",
        enumObject: VENDOR_INVOICE_SORT_KEY,
        enumValues: [
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_CREATED_AT,
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_MODIFIED_AT,
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_APPROVED_ON,
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_COMPLETED_ON,
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_REFERENCE_ID,
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_FINAL_REF_NUMBER,
            VENDOR_INVOICE_SORT_KEY.VENDOR_INVOICE_SORT_KEY_TOTAL_VALUE,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Search for Vendor Invoices" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${billDatesSection.outerHTML}
                        <hr class="m-5">
                        ${statusFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${timeFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId
    } 
}