/**Stores the information of each menu item */
export interface MenuItem {
    menu_uid: string
    menu_name: string
    menu_icon: string
    href: string
    parent_menu: MenuItem | null
    /**Denotes if this value will be displayed on the UI */
    visibleOnUI: boolean
    /**Stores the list of URLs that will be affected when a notification with this menu_uid is received by the client */
    affected_urls: string[]
}

// Stores all the primary sidebar menus
export let sidebarMenusList: MenuItem[] = [];
// Map from menu_uid to the menu item of the sidebarMenusList
export const sidebarMenusMap: { [name: string]: MenuItem } = {};

// Stores all the vertical menus
export let verticalMenusList: MenuItem[] = [];
// Map from menu_uid to the menu item of the verticalMenusList
export const verticalMenusMap: { [name: string]: MenuItem } = {};

export let mainMenusClassifications: MenuItem[] = [];
export const adminMenu: MenuItem = {
    menu_uid: "Admin", menu_name: "Admin", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const ordersMenu: MenuItem = {
    menu_uid: "Orders", menu_name: "Orders", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const inventoryMenu: MenuItem = {
    menu_uid: "Inventory", menu_name: "Inventory", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const paymentsMenu: MenuItem = { 
    menu_uid: "Payments", menu_name: "Payments", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const quotationsMenu: MenuItem = {
    menu_uid: "Quotations", menu_name: "Quotations", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};
export const qaMenu: MenuItem = {
    menu_uid: "QA", menu_name: "QA", menu_icon: "", href: "", parent_menu: null, visibleOnUI: true, affected_urls: [],
};

mainMenusClassifications.push(adminMenu);
mainMenusClassifications.push(ordersMenu);
mainMenusClassifications.push(inventoryMenu);
mainMenusClassifications.push(paymentsMenu);
mainMenusClassifications.push(quotationsMenu);
mainMenusClassifications.push(qaMenu);

export let secondaryMenusList: MenuItem[] = [];
export let tertiaryMenusList: MenuItem[] = [];

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Admin
export const dashboardMenu: MenuItem = {
    menu_uid: "dashboard", menu_name: "Dashboard", menu_icon: "bx-home", href: "/ui/dash", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(dashboardMenu);

export const profileMenu: MenuItem = {
    menu_uid: "profile", menu_name: "Profile", menu_icon: "bx-user", href: "/ui/profile", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(profileMenu);

export const associatesMenu: MenuItem = {
    menu_uid: "associates", menu_name: "Associates", menu_icon: "bx-phone-call", href: "/ui/associates", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(associatesMenu);
export const associateFiltersMenu: MenuItem = {
    menu_uid: "associate-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/associates/filters", parent_menu: associatesMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(associateFiltersMenu);


export const materialsMenu: MenuItem = {
    menu_uid: "materials", menu_name: "Materials", menu_icon: "bx-cart", href: "/ui/materials", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(materialsMenu);
export const materialFiltersMenu: MenuItem = {
    menu_uid: "material-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/materials/filters", parent_menu: materialsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(materialFiltersMenu);

// Streams menus
export const streamsMenu: MenuItem = {
    menu_uid: "streams", menu_name: "Streams", menu_icon: "bx-message", href: "/ui/streams", parent_menu: adminMenu, visibleOnUI: true, affected_urls: [],
}
secondaryMenusList.push(streamsMenu);


export const streamOpenMenu: MenuItem = {
    menu_uid: "stream-open", menu_name: "Open", menu_icon: "bx-envelope-open", href: "/ui/streams/open", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(streamOpenMenu);

export const streamIndividualMenu: MenuItem = {
    menu_uid: "stream-individual", menu_name: "Individual", menu_icon: "bx-envelope-open", href: "/ui/streams/i/:uuid", parent_menu: streamsMenu, visibleOnUI: false, affected_urls: [],
}
tertiaryMenusList.push(streamIndividualMenu);

// export const streamCompletedMenu: MenuItem = {
//     menu_uid: "stream-completed", menu_name: "Completed", menu_icon: "bx-lock", href: "/ui/streams/completed", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
// }
// tertiaryMenusList.push(streamCompletedMenu);

// export const streamCancelledMenu: MenuItem = {
//     menu_uid: "stream-cancelled", menu_name: "Cancelled", menu_icon: "bx-window-close", href: "/ui/streams/cancelled", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
// }
// tertiaryMenusList.push(streamCancelledMenu);

export const streamFiltersMenu: MenuItem = {
    menu_uid: "stream-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/streams/filters", parent_menu: streamsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(streamFiltersMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Orders
export const purchaseOrdersMenu: MenuItem = {
    menu_uid: "purchase-orders", menu_name: "Purchase Orders", menu_icon: "bx-purchase-tag", href: "/ui/purchase-orders", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}
export const vendorInvoicesMenu: MenuItem = {
    menu_uid: "vendor-invoices", menu_name: "Vendor Invoices", menu_icon: "bx-list-check", href: "/ui/vendor-invoices", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}
export const outwardJobsMenu: MenuItem = {
    menu_uid: "outward-jobs", menu_name: "Outward Jobs", menu_icon: "bx-horizontal-right", href: "/ui/outward-jobs", parent_menu: ordersMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(purchaseOrdersMenu);
secondaryMenusList.push(vendorInvoicesMenu);
secondaryMenusList.push(outwardJobsMenu);


export const purchaseOrderFiltersMenu: MenuItem = {
    menu_uid: "purchase-order-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/purchase-orders/filters", parent_menu: purchaseOrdersMenu, visibleOnUI: true, affected_urls: [],
}
export const purchaseOrderInsightsMenu: MenuItem = {
    menu_uid: "purchase-order-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/purchase-orders/insights", parent_menu: purchaseOrdersMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(purchaseOrderFiltersMenu);
tertiaryMenusList.push(purchaseOrderInsightsMenu);

export const vendorInvoiceFiltersMenu: MenuItem = {
    menu_uid: "vendor-invoice-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/vendor-invoices/filters", parent_menu: vendorInvoicesMenu, visibleOnUI: true, affected_urls: [],
}
export const vendorInvoiceInsightsMenu: MenuItem = {
    menu_uid: "vendor-invoice-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/vendor-invoices/insights", parent_menu: vendorInvoicesMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(vendorInvoiceFiltersMenu);
tertiaryMenusList.push(vendorInvoiceInsightsMenu);

export const outwardJobFiltersMenu: MenuItem = {
    menu_uid: "outward-job-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/outward-jobs/filters", parent_menu: outwardJobsMenu, visibleOnUI: true, affected_urls: [],
}
export const outwardJobInsightsMenu: MenuItem = {
    menu_uid: "outward-job-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/outward-jobs/insights", parent_menu: outwardJobsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(outwardJobFiltersMenu);
tertiaryMenusList.push(outwardJobInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Inventory
export const goodsReceiptMenu: MenuItem = {
    menu_uid: "goods-receipts", menu_name: "Goods Receipts", menu_icon: "bx-receipt", href: "/ui/goods-receipts", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}
export const outwardJobFIM: MenuItem = {
    menu_uid: "outward-jobs-free-issue-materials", menu_name: "Free Issues", menu_icon: "bxs-send", href: "/ui/outward-jobs-free-issue-materials", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}
export const outwardJobFIMR: MenuItem = {
    menu_uid: "outward-jobs-free-issue-materials-returns", menu_name: "Free Issue Returns", menu_icon: "bx-horizontal-left", href: "/ui/outward-jobs-free-issue-materials-returns", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}
export const purchaseReturnMenu: MenuItem = {
    menu_uid: "purchase-returns", menu_name: "Purchase Returns", menu_icon: "bx-rotate-left", href: "/ui/purchase-returns", parent_menu: inventoryMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(goodsReceiptMenu);
secondaryMenusList.push(outwardJobFIM);
secondaryMenusList.push(outwardJobFIMR);
secondaryMenusList.push(purchaseReturnMenu);


export const goodsReceiptFiltersMenu: MenuItem = {
    menu_uid: "goods-receipt-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/goods-receipts/filters", parent_menu: goodsReceiptMenu, visibleOnUI: true, affected_urls: [],
}
export const goodsReceiptInsightsMenu: MenuItem = {
    menu_uid: "goods-receipt-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/goods-receipts/insights", parent_menu: goodsReceiptMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(goodsReceiptFiltersMenu);
tertiaryMenusList.push(goodsReceiptInsightsMenu);

export const outwardJobFIMFiltersMenu: MenuItem = {
    menu_uid: "outward-jobs-free-issue-material-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/outward-jobs-free-issue-materials/filters", parent_menu: outwardJobFIM, visibleOnUI: true, affected_urls: [],
}
export const outwardJobFIMInsightsMenu: MenuItem = {
    menu_uid: "outward-jobs-free-issue-material-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/outward-jobs-free-issue-materials/insights", parent_menu: outwardJobFIM, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(outwardJobFIMFiltersMenu);
tertiaryMenusList.push(outwardJobFIMInsightsMenu);


export const outwardJobFIMRFiltersMenu: MenuItem = {
    menu_uid: "outward-jobs-free-issue-materials-returns-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/outward-jobs-free-issue-materials-returns/filters", parent_menu: outwardJobFIMR, visibleOnUI: true, affected_urls: [],
}
export const outwardJobFIMRInsightsMenu: MenuItem = {
    menu_uid: "outward-jobs-free-issue-materials-returns-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/outward-jobs-free-issue-materials-returns/insights", parent_menu: outwardJobFIMR, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(outwardJobFIMRFiltersMenu);
tertiaryMenusList.push(outwardJobFIMRInsightsMenu);

export const purchaseReturnFiltersMenu: MenuItem = {
    menu_uid: "purchase-returns-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/purchase-returns/filters", parent_menu: purchaseReturnMenu, visibleOnUI: true, affected_urls: [],
}
export const purchaseReturnInsightsMenu: MenuItem = {
    menu_uid: "purchase-returns-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/purchase-returns/insights", parent_menu: purchaseReturnMenu, visibleOnUI: true, affected_urls: [],
}

tertiaryMenusList.push(purchaseReturnFiltersMenu);
tertiaryMenusList.push(purchaseReturnInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Payments

export const debitNotesMenu: MenuItem = {
    menu_uid: "debit-notes", menu_name: "Debit Notes", menu_icon: "bx-credit-card-front", href: "/ui/debit-notes", parent_menu: paymentsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(debitNotesMenu);

export const debitNoteFiltersMenu: MenuItem = {
    menu_uid: "debit-note-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/debit-notes/filters", parent_menu: debitNotesMenu, visibleOnUI: true, affected_urls: [],
}
export const debitNoteInsightsMenu: MenuItem = {
    menu_uid: "debit-note-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/debit-notes/insights", parent_menu: debitNotesMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(debitNoteFiltersMenu);
tertiaryMenusList.push(debitNoteInsightsMenu);

export const purchasePaymentsMenu: MenuItem = {
    menu_uid: "purchase-payments", menu_name: "Payments", menu_icon: "bx-money", href: "/ui/purchase-payments", parent_menu: paymentsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(purchasePaymentsMenu);

export const purchasePaymentFiltersMenu: MenuItem = {
    menu_uid: "purchase-payment-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/purchase-payments/filters", parent_menu: purchasePaymentsMenu, visibleOnUI: true, affected_urls: [],
}
export const purchasePaymentInsightsMenu: MenuItem = {
    menu_uid: "purchase-payment-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/purchase-payments/insights", parent_menu: purchasePaymentsMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(purchasePaymentFiltersMenu);
tertiaryMenusList.push(purchasePaymentInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Quotations

export const quotationRequestsMenu: MenuItem = {
    menu_uid: "quotation-requests", menu_name: "Quotation Requests", menu_icon: "bx-git-pull-request", href: "/ui/quotation-requests", parent_menu: quotationsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(quotationRequestsMenu);

export const quotationRequestFiltersMenu: MenuItem = {
    menu_uid: "quotation-request-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/quotation-requests/filters", parent_menu: quotationRequestsMenu, visibleOnUI: true, affected_urls: [],
}
// export const quotationRequestInsightsMenu: MenuItem = {
//     menu_uid: "quotation-request-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/quotation-requests/insights", parent_menu: quotationRequestsMenu, visibleOnUI: true, affected_urls: [],
// }
tertiaryMenusList.push(quotationRequestFiltersMenu);
// tertiaryMenusList.push(quotationRequestInsightsMenu);


export const quotationResponsesMenu: MenuItem = {
    menu_uid: "quotation-responses", menu_name: "Quotation Responses", menu_icon: "bx-mail-send", href: "/ui/quotation-responses", parent_menu: quotationsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(quotationResponsesMenu);

export const quotationResponseFiltersMenu: MenuItem = {
    menu_uid: "quotation-response-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/quotation-responses/filters", parent_menu: quotationResponsesMenu, visibleOnUI: true, affected_urls: [],
}
// export const quotationResponseInsightsMenu: MenuItem = {
//     menu_uid: "quotation-response-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/quotation-responses/insights", parent_menu: quotationResponsesMenu, visibleOnUI: true, affected_urls: [],
// }
tertiaryMenusList.push(quotationResponseFiltersMenu);
// tertiaryMenusList.push(quotationResponseInsightsMenu);


export const purchaseEnquiriesMenu: MenuItem = {
    menu_uid: "purchase-enquiries", menu_name: "Purchase Enquiries", menu_icon: "bx-question-mark", href: "/ui/purchase-enquiries", parent_menu: quotationsMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(purchaseEnquiriesMenu);

export const purchaseEnquiryFiltersMenu: MenuItem = {
    menu_uid: "purchase-enquiry-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/purchase-enquiries/filters", parent_menu: purchaseEnquiriesMenu, visibleOnUI: true, affected_urls: [],
}
// export const purchaseEnquiryInsightsMenu: MenuItem = {
//     menu_uid: "purchase-enquiry-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/purchase-enquiries/insights", parent_menu: purchaseEnquiriesMenu, visibleOnUI: true, affected_urls: [],
// }
tertiaryMenusList.push(purchaseEnquiryFiltersMenu);
// tertiaryMenusList.push(purchaseEnquiryInsightsMenu);

// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// QA

export const qaSamplesMenu: MenuItem = {
    menu_uid: "qa-samples", menu_name: "QA Samples", menu_icon: "bx-test-tube", href: "/ui/qa-samples", parent_menu: qaMenu, visibleOnUI: true, affected_urls: [],
}

secondaryMenusList.push(qaSamplesMenu);

export const qaSampleFiltersMenu: MenuItem = {
    menu_uid: "qa-sample-filters", menu_name: "Filter", menu_icon: "bx-search-alt", href: "/ui/qa-samples/filters", parent_menu: qaSamplesMenu, visibleOnUI: true, affected_urls: [],
}
export const qaSampleInsightsMenu: MenuItem = {
    menu_uid: "qa-sample-insights", menu_name: "Insights", menu_icon: "bx-scatter-chart", href: "/ui/qa-samples/insights", parent_menu: qaSamplesMenu, visibleOnUI: true, affected_urls: [],
}
tertiaryMenusList.push(qaSampleFiltersMenu);
tertiaryMenusList.push(qaSampleInsightsMenu);