import { _returnInCents, createObjectFromForm, downloadButtonClass, downloadData, randomId, round, setupDownloadForFilters, showFailureAlert, showSuccessAlert } from "../../utilities";
import { context } from "./../../router";
import { VendorStreamsServiceCountReq, VendorStreamsServiceFilterReq } from "@kernelminds/scailo-sdk";
import { getVendorStreamsServiceClient } from "../../clients";
import { viewVendorStreams } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";

export function handleStreamFilters(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId } = _renderPageFilters("Records");
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handleStreamFilters(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));
    let client = getVendorStreamsServiceClient();

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (tableContainer.firstChild) {
            tableContainer.removeChild(tableContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let [filterResp, countResp] = await Promise.all([
            client.filter(new VendorStreamsServiceFilterReq(createObjectFromForm(formId))),
            client.count(new VendorStreamsServiceCountReq(createObjectFromForm(formId)))
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Records`;

        const filterRecords = filterResp.list;

        if (!filterRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }

        showSuccessAlert(`${countResp.count} records match the filter criteria`);
        
        let tableDiv = document.createElement("div");
        tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded";
        tableContainer.appendChild(tableDiv);

        const recordsDiv = await viewVendorStreams(filterRecords);
        setupDownloadForFilters(recordsDiv, tableDiv, "Streams", 1);

        // Setup PDF downloads
        // let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
        // for (let i = 0; i < pdfDownloadButtons.length; i++) {
        //     let btn = <HTMLButtonElement>pdfDownloadButtons[i];
        //     btn.addEventListener("click", async evt => {
        //         evt.preventDefault();

        //         const originalButtonHTML = btn.innerHTML;
        //         btn.disabled = true;
        //         btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
        //         let file = await client.downloadByUUID({uuid: btn.getAttribute("data-uuid") || ""});

        //         btn.disabled = false;
        //         btn.innerHTML = originalButtonHTML;

        //         downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
        //     });
        // }
    });
}