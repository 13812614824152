import { 
    materialFiltersMenu, 
    // materialInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleMaterialFilters } from "./materials.filters";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(materialFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, materialFiltersMenu)) {
            handleMaterialFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(materialInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, materialInsightsMenu)) {
    //         handleMaterialInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
}