import { _returnInCents, colorizeInvertedCentsToMoney, convertCentsToMoney, createObjectFromForm, downloadButtonClass, downloadData, randomId, renderTableWithCSVHeadersAndRows, round, setupDownloadForFilters, showFailureAlert, showSuccessAlert } from "../../utilities";
import { context } from "./../../router";
import { Family, PurchasesOrdersService, PurchasesOrdersServiceCountReq, PurchasesOrdersServiceFilterReq } from "@kernelminds/scailo-sdk";
import { getPurchaseOrderServiceClient } from "../../clients";
import { failureIcon, successIcon, viewPurchasesOrders } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";
import { PromiseClient } from "@connectrpc/connect";
import { familiesListFromIDs, purchaseorderInventoryMatch } from "../../fetches";
import { protoInt64 } from "@bufbuild/protobuf";

export function handlePurchaseOrderFilters(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let { html, formId, resetButtonId, getButtonId } = _renderPageFilters("Records");
    let filtersHtml = document.createElement("div");
    filtersHtml.innerHTML = html

    content.appendChild(filtersHtml);

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    (<HTMLButtonElement>document.getElementById(resetButtonId)).addEventListener("click", async evt => {
        evt.preventDefault();
        handlePurchaseOrderFilters(ctx);
    });

    let getButton = (<HTMLButtonElement>document.getElementById(getButtonId));

    getButton.addEventListener("click", async evt => {
        evt.preventDefault();
        while (tableContainer.firstChild) {
            tableContainer.removeChild(tableContainer.firstChild);
        }

        getButton.disabled = true;
        getButton.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;

        let client = getPurchaseOrderServiceClient();
        let [filterResp, countResp] = await Promise.all([
            client.filter(new PurchasesOrdersServiceFilterReq(createObjectFromForm(formId))),
            client.count(new PurchasesOrdersServiceCountReq(createObjectFromForm(formId)))
        ]);

        getButton.disabled = false;
        getButton.innerText = `Get Records`;

        const filterRecords = filterResp.list;

        if (!filterRecords.length) {
            showFailureAlert("No Records Found");
            return;
        }

        showSuccessAlert(`${countResp.count} records match the filter criteria`);
        
        let tableDiv = document.createElement("div");
        tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded";
        tableContainer.appendChild(tableDiv);

        const inventoryMatchClass = randomId();

        const recordsDiv = await viewPurchasesOrders(filterRecords, { inventoryMatchClass });
        setupDownloadForFilters(recordsDiv, tableDiv, "Purchase Orders", 3);

        // Setup PDF downloads
        let pdfDownloadButtons = tableDiv.getElementsByClassName(downloadButtonClass);
        for (let i = 0; i < pdfDownloadButtons.length; i++) {
            let btn = <HTMLButtonElement>pdfDownloadButtons[i];
            btn.addEventListener("click", async evt => {
                evt.preventDefault();

                const originalButtonHTML = btn.innerHTML;
                btn.disabled = true;
                btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
                let file = await client.downloadByUUID({uuid: btn.getAttribute("data-uuid") || ""});

                btn.disabled = false;
                btn.innerHTML = originalButtonHTML;

                downloadData(file.content, "pdf", file.name.replace(".pdf", ""));
            });
        }

        // Setup inventory match
        handlePurchaseOrderInventoryMatch(inventoryMatchClass, tableDiv, client);
    });
}

/**Renders the inventory match for the purchase order in a modal */
export async function handlePurchaseOrderInventoryMatch(inventoryMatchClass: string, tableDiv: HTMLDivElement, client: PromiseClient<typeof PurchasesOrdersService>) {
    let buttons = tableDiv.getElementsByClassName(inventoryMatchClass);
    for (let i = 0; i < buttons.length; i++) {
        let btn = <HTMLButtonElement>buttons[i];
        btn.addEventListener("click", async evt => {
            evt.preventDefault();

            const originalButtonHTML = btn.innerHTML;
            btn.disabled = true;
            btn.innerHTML = `<span class="loading loading-infinity loading-md"></span>`;
            let matchList = await purchaseorderInventoryMatch(btn.getAttribute("data-uuid") || "", client);
            btn.disabled = false;
            btn.innerHTML = originalButtonHTML;

            let familiesMap: Map<bigint, Family> = new Map();
            (await familiesListFromIDs(Array.from(new Set(matchList.map(m => {
                return m.familyId;
            }))))).forEach(fam => {
                familiesMap.set(fam.metadata!.id, fam);
            });
            
            // Create a table here
            const headers = ["S.No.", "Code", "Material Name", "Ordered Qty", "Received Qty", "Returned Qty", "To Receive Qty", "Received Qty Match", "Invoiced Qty", "Debit Note Qty", "To Invoice Qty", "Invoiced Qty Match"]
            let rows = matchList.map((m, i) => {
                let fam = familiesMap.get(m.familyId) as Family;
                const toRecvQty = m.ordered - m.received + m.returned;
                const toInvoiceQty = m.ordered - m.invoiced + m.debited;
                return [
                    (i + 1).toString(),
                    fam.code,
                    fam.name,
                    convertCentsToMoney(m.ordered),
                    convertCentsToMoney(m.received),
                    convertCentsToMoney(m.returned),
                    colorizeInvertedCentsToMoney(toRecvQty),
                    toRecvQty == protoInt64.zero ? successIcon : failureIcon,

                    convertCentsToMoney(m.invoiced),
                    convertCentsToMoney(m.debited),
                    colorizeInvertedCentsToMoney(toInvoiceQty),
                    toInvoiceQty == protoInt64.zero ? successIcon : failureIcon
                ];
            });

            let table = renderTableWithCSVHeadersAndRows({ title: `Inventory Match for ${btn.getAttribute("data-name") || ""}`, headers_array: headers, rows_array: rows, responsive: false });
            // Render the table inside a modal
            let dialog = document.createElement("dialog");
            dialog.className = "modal";
            dialog.id = randomId();
            dialog.innerHTML = `
            <div class="max-w-full modal-box dark:text-white text-gray-700">
                <form method="dialog">
                <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                ${table.table.outerHTML}
            </div>          
            `;

            document.body.appendChild(dialog);
            dialog.showModal()
        });
    }
}

function renderFilters() {
    return `
    <div class="grid grid-cols-1 gap-6 mb-6">
        <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-gray-50 w-full shadow-lg rounded">
            <div class="rounded-t mb-0 px-0 border-0">
                <form class="">
                    <div class="grid grid-cols-12 place-items-center">
                        <div class="col-span-12 md:col-span-9 pl-4">
                            <div class="grid grid-cols-12">
                                <div class="col-span-12 md:col-span-3 pl-4">
                                    <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name</label>
                                    <div class="mt-2">
                                        <input type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <div class="bg-gray-70 border rounded text-gray-600 text-sm font-medium pl-2 overflow-y-scroll max-h-8">
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                            <div class="hover:text-blue-500 cursor-pointer">Create landing page</div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    `
}