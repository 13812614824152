import { Router, context } from "./router";
import { showFailureAlert } from "./utilities";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add("/", (ctx) => {
        Login(ctx);
    });
}

export function setupUsernameDisplay() {
    let usernameEl = <HTMLElement>document.getElementById("username");
    usernameEl.innerText = "Hi " + localStorage.getItem("username") || "";
}

function Login(ctx: context) {
    let usernameEl = <HTMLInputElement>document.getElementById("username");
    let passwordEl = <HTMLInputElement>document.getElementById("password");
    let otpEl = <HTMLInputElement>document.getElementById("otp");
    let loginEl = <HTMLButtonElement>document.getElementById("login");

    loginEl.addEventListener("click", async evt => {
        evt.preventDefault();
        let obj = {
            "username": usernameEl.value,
            "plainTextPassword": passwordEl.value,
            "otp": otpEl.value,
        };
        let resp = <string | undefined | null>await PostData("/login", obj).catch(err => {
            showFailureAlert("Invalid Login Credentials");
            return;
        });

        if (resp !== undefined && resp !== null) {
            localStorage.setItem("vendor_uuid", resp["vendor_uuid"]);
            localStorage.setItem("username", usernameEl.value);
            location.href = "/ui/dash";
        }

    });
}

export function setupLogoutHandler() {
    let logoutEl = <HTMLButtonElement>document.getElementById("logout");
    logoutEl.addEventListener("click", async evt => {
        evt.preventDefault();
        let resp = <string | undefined | null>await PostData("/logout", {}).catch(err => {
            console.error(err);
            showFailureAlert("Couldn't logout");
            return;
        });

        if (resp !== undefined && resp !== null) {
            localStorage.clear();
            location.href = "/";
        }
    });
}

/**Makes a POST request without increasing the request count, and returns the result */
export async function PostData(url: string, body: Object, respType?: "json" | "arraybuffer"): Promise<Object> {
    if (respType == null || respType == undefined) {
        respType = "json";
    }

    let resp = await makeReq(url, body, respType);
    return resp
}

/**Internal function that makes the actual request */
async function makeReq(url: string, body: Object, respType: "json" | "arraybuffer"): Promise<Object> {
    var headers = <HeadersInit>{
        'Content-Type': 'application/json',
    }

    return fetch(`${location.protocol}//${location.host}` + url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
    }).then(response => {
        if (response.status == 200) {
            if (respType == "json") {
                return response.json();
            } else if (respType == "arraybuffer") {
                return response.arrayBuffer();
            }
        } else {
            throw new Error(response.statusText);
        }
    }).catch((e) => {
        throw e
    });
}