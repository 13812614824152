import * as ui from "../../ui";
import { _returnInCents, randomId } from "../../utilities";
import { VENDOR_ITEM_SORT_KEY, BOOL_FILTER, VENDOR_ITEM_STATUS } from "@kernelminds/scailo-sdk";

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Search Key", inputType: "text", dataMapper: "searchKey", dataType: "string", value: "", mdColSpan: 6, helpText: "Enter a search key. Filter records by the value of this field. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Vendor Material Code", inputType: "text", dataMapper: "vendorFamilyCode", dataType: "string", value: "", mdColSpan: 6, helpText: "Material code as per the vendor. Filter records by the value of this field. Leave it empty to ignore this field." }));
    
    return grid
}

/**Renders the status filters filter section */
function renderStatusFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Status Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Status",
        nameSplitsAt: "VENDOR_ITEM_STATUS_",
        enumObject: VENDOR_ITEM_STATUS,
        dataMapper: "status",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by record status. Set it to 'Any' to ignore this field.",
        excludeZeroethValuedEnum: false,
    }));

    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Is Active",
        nameSplitsAt: "BOOL_FILTER_",
        enumObject: BOOL_FILTER,
        dataMapper: "isActive",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by active status. Set it to 'Any' to ignore this field. Set it to 'True' to only show active records. Set it to 'False' to only show inactive records.",
        excludeZeroethValuedEnum: false,
    }));

    return grid
}

export function _renderPageFilters(context: "Records" | "Insights") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let statusFiltersSection = renderStatusFiltersSection();
    let sortFiltersSection = ui.renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "VENDOR_ITEM_SORT_KEY_",
        enumObject: VENDOR_ITEM_SORT_KEY,
        enumValues: [
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_CREATED_AT,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MODIFIED_AT,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_APPROVED_ON,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_VENDOR_FAMILY_CODE,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_PRICE,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MIN_ORDER_QTY,
            VENDOR_ITEM_SORT_KEY.VENDOR_ITEM_SORT_KEY_MAX_ORDER_QTY,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Search for Materials" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${statusFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId
    } 
}