
import { Router } from "./../router";
import * as goodsreceipts from "./goodsreceipts/routes";
import * as purchasereturns from "./purchasereturns/routes";
import * as freeissues from "./freeissues/routes";
import * as freeissuereturns from "./freeissuereturns/routes";

/**All the routes of this module */
export function Routes(r: Router) {
    goodsreceipts.Routes(r);
    purchasereturns.Routes(r);
    freeissues.Routes(r);
    freeissuereturns.Routes(r);
}