import { showFailureAlert } from "../../utilities";
import { context } from "./../../router";
import { BOOL_FILTER, SORT_ORDER, VENDOR_STREAM_LIFECYCLE, VENDOR_STREAM_SORT_KEY, VendorStreamsServiceCountReq, VendorStreamsServiceFilterReq } from "@kernelminds/scailo-sdk";
import { getUsersServiceClient, getVendorStreamsServiceClient } from "../../clients";
import { viewVendorStreams } from "../../tables";
import { _renderPageFilters } from "./searchcomponents";
import { protoInt64 } from "@bufbuild/protobuf";

export async function handleStreamOpen(ctx: context) {
    let content = <HTMLDivElement>document.getElementById("central-content");
    while (content.firstChild) {
        content.removeChild(content.firstChild);
    }
    let client = getVendorStreamsServiceClient();
    let usersClient = getUsersServiceClient();

    let tableContainer = document.createElement("div");
    content.appendChild(tableContainer);

    let tableDiv = document.createElement("div");
    tableDiv.className = "overflow-x-auto p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded";
    tableContainer.appendChild(tableDiv);

    let userInfo = await usersClient.viewByUsername({ searchKey: localStorage.getItem("username") || "" });

    let [filterResp] = await Promise.all([
        client.filter(new VendorStreamsServiceFilterReq({
            isActive: BOOL_FILTER.BOOL_FILTER_TRUE,
            count: protoInt64.parse(-1),
            sortOrder: SORT_ORDER.DESCENDING,
            sortKey: VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_MODIFIED_AT,
            status: VENDOR_STREAM_LIFECYCLE.VENDOR_STREAM_LIFECYCLE_OPEN,
            vendorSubscriberUserId: userInfo.metadata?.id,
        }))
    ]);

    const filterRecords = filterResp.list;

    if (!filterRecords.length) {
        showFailureAlert("No Records Found");
        return;
    }

    const recordsDiv = await viewVendorStreams(filterRecords, "Open Streams");
    tableDiv.appendChild(recordsDiv.table);
}