
import { Router } from "./../router";
import * as purchaseorders from "./purchaseorders/routes";
import * as vendorinvoices from "./vendorinvoices/routes";
import * as outwardjobs from "./outwardjobs/routes";

/**All the routes of this module */
export function Routes(r: Router) {
    purchaseorders.Routes(r);
    vendorinvoices.Routes(r);
    outwardjobs.Routes(r);
}