import { createPromiseClient, Interceptor, UnaryResponse, StreamResponse, ConnectError, Code } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AssociatesService, ComponentsService, CurrenciesService, DebitNotesService, EquipmentsService, FamiliesService, FeedstocksService, GeneralSettingsService, GoodsReceiptsService, InfrastructuresService, MerchandisesService, OutwardJobsFreeIssueMaterialsReturnsService, OutwardJobsFreeIssueMaterialsService, OutwardJobsService, ProductsService, PurchasesEnquiriesService, PurchasesOrdersService, PurchasesPaymentsService, PurchasesReturnsService, QCSamplesService, QuotationsRequestsService, QuotationsResponsesService, UnitsOfMaterialsService, UsersService, VaultService, VendorInvoicesService, VendorsService, VendorStreamsService } from "@kernelminds/scailo-sdk";
import { showFailureAlert } from "./utilities";

// appendAuthToken is an interceptor that appends the auth token to the request prior to sending the request
const appendAuthToken: Interceptor = (next) => async (req) => {
    return await next(req).catch((err: ConnectError) => {
        if (err) {
            if (err.code == Code.Unauthenticated) {
                location.href = "/";
                return;
            }
            showFailureAlert(err.message);
        }
    }).then(resp => {
        return <UnaryResponse | StreamResponse>resp;
    });
};

function getTransport() {
    return createConnectTransport({
        // Need to use binary format (at least for the time being)
        baseUrl: location.origin, useBinaryFormat: false, interceptors: [
            appendAuthToken
        ]
    });
}

/**Returns the client for the purchase order service */
export function getPurchaseOrderServiceClient() {
    return createPromiseClient(PurchasesOrdersService, getTransport());
}

/**Returns the client for the goods receipt service */
export function getGoodsReceiptServiceClient() {
    return createPromiseClient(GoodsReceiptsService, getTransport());
}

/**Returns the client for the vendor invoice service */
export function getVendorInvoiceServiceClient() {
    return createPromiseClient(VendorInvoicesService, getTransport());
}

/**Returns the client for the debit note service */
export function getDebitNoteServiceClient() {
    return createPromiseClient(DebitNotesService, getTransport());
}

/**Returns the client for the purchase return service */
export function getPurchaseReturnServiceClient() {
    return createPromiseClient(PurchasesReturnsService, getTransport());
}

/**Returns the client for the purchase payment service */
export function getPurchasePaymentServiceClient() {
    return createPromiseClient(PurchasesPaymentsService, getTransport());
}

/**Returns the client for the outward job service */
export function getOutwardJobServiceClient() {
    return createPromiseClient(OutwardJobsService, getTransport());
}

/**Returns the client for the qc samples service */
export function getQCSamplesServiceClient() {
    return createPromiseClient(QCSamplesService, getTransport());
}

/**Returns the client for the outward job free issue material service */
export function getOutwardJobFreeIssueMaterialServiceClient() {
    return createPromiseClient(OutwardJobsFreeIssueMaterialsService, getTransport());
}

/**Returns the client for the outward job free issue material return service */
export function getOutwardJobFreeIssueMaterialReturnServiceClient() {
    return createPromiseClient(OutwardJobsFreeIssueMaterialsReturnsService, getTransport());
}

/**Returns the client for the quotation request service */
export function getQuotationRequestsServiceClient() {
    return createPromiseClient(QuotationsRequestsService, getTransport());
}

/**Returns the client for the quotation response service */
export function getQuotationResponsesServiceClient() {
    return createPromiseClient(QuotationsResponsesService, getTransport());
}

/**Returns the client for the purchase enquiry service */
export function getPurchaseEnquiriesServiceClient() {
    return createPromiseClient(PurchasesEnquiriesService, getTransport());
}

/**Returns the client for currencies service */
export function getCurrenciesServiceClient() {
    return createPromiseClient(CurrenciesService, getTransport());
}

/**Returns the client for units of materials service */
export function getUnitsOfMaterialsServiceClient() {
    return createPromiseClient(UnitsOfMaterialsService, getTransport());
}

/**Returns the client for families service */
export function getFamiliesServiceClient() {
    return createPromiseClient(FamiliesService, getTransport());
}

/**Returns the client for components service */
export function getComponentsServiceClient() {
    return createPromiseClient(ComponentsService, getTransport());
}

/**Returns the client for feedstock service */
export function getFeedstockServiceClient() {
    return createPromiseClient(FeedstocksService, getTransport());
}

/**Returns the client for merchandise service */
export function getMerchandiseServiceClient() {
    return createPromiseClient(MerchandisesService, getTransport());
}

/**Returns the client for infrastructure service */
export function getInfrastructureServiceClient() {
    return createPromiseClient(InfrastructuresService, getTransport());
}

/**Returns the client for equipment service */
export function getEquipmentServiceClient() {
    return createPromiseClient(EquipmentsService, getTransport());
}

/**Returns the client for products service */
export function getProductsServiceClient() {
    return createPromiseClient(ProductsService, getTransport());
}

/**Returns the client for vendors service */
export function getVendorsServiceClient() {
    return createPromiseClient(VendorsService, getTransport());
}

/**Returns the client for associates service */
export function getAssociatesServiceClient() {
    return createPromiseClient(AssociatesService, getTransport());
}

/**Returns the client for vendor streams service */
export function getVendorStreamsServiceClient() {
    return createPromiseClient(VendorStreamsService, getTransport());
}

/**Returns the client for users service */
export function getUsersServiceClient() {
    return createPromiseClient(UsersService, getTransport());   
}

/**Returns the client for general settings service */
export function getGeneralSettingsServiceClient() {
    return createPromiseClient(GeneralSettingsService, getTransport());
}

/**Returns the client for vault service */
export function getVaultServiceClient() {
    return createPromiseClient(VaultService, getTransport());   
}