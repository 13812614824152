import { 
    purchaseEnquiryFiltersMenu, 
    // purchaseEnquiryInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handlePurchaseEnquiryFilters } from "./purchaseenquiries.filters";
import { handlePurchaseEnquiryInsights } from "./purchaseenquiries.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(purchaseEnquiryFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchaseEnquiryFiltersMenu)) {
            handlePurchaseEnquiryFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(purchaseEnquiryInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, purchaseEnquiryInsightsMenu)) {
    //         handlePurchaseEnquiryInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
}