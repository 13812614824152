import * as ui from "../../ui";
import { _returnInCents, randomId } from "../../utilities";
import { VENDOR_STREAM_SORT_KEY, BOOL_FILTER, VENDOR_STREAM_LIFECYCLE } from "@kernelminds/scailo-sdk";

function renderPrimaryFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Primary Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Title", inputType: "text", dataMapper: "title", dataType: "string", value: "", mdColSpan: 6, helpText: "Title of the stream. Filter records by the value of this field. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Internal Reference", inputType: "text", dataMapper: "internalRef", dataType: "string", value: "", mdColSpan: 6, helpText: "Internal Reference of the stream. Filter records by the value of this field. Leave it empty to ignore this field." }));
    
    return grid
}

/**Renders the status filters filter section */
function renderStatusFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Status Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Status",
        nameSplitsAt: "VENDOR_STREAM_LIFECYCLE_",
        enumObject: VENDOR_STREAM_LIFECYCLE,
        dataMapper: "status",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by record status. Set it to 'Any' to ignore this field.",
        excludeZeroethValuedEnum: false,
    }));
    
    contentGrid.appendChild(ui.renderSelectForEntireEnum({
        id: randomId(),
        label: "Is Active",
        nameSplitsAt: "BOOL_FILTER_",
        enumObject: BOOL_FILTER,
        dataMapper: "isActive",
        dataType: "number",
        value: "",
        mdColSpan: 6,
        helpText: "Filter by active status. Set it to 'Any' to ignore this field. Set it to 'True' to only show active records. Set it to 'False' to only show inactive records.",
        excludeZeroethValuedEnum: false,
    }));

    return grid
}

/**Renders the time filters filter section */
function renderTimeFiltersSection() {
    let { grid, contentGrid } = ui.renderFilterPrimarySubSection({ subsectionTitle: "Time Filters", titleMdColSpan: 3 });

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Creation Date", inputType: "date", dataMapper: "creationTimestampStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Creation Date is the minimum creation date to be considered. If set, all records from this creation date are returned. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Creation Date", inputType: "date", dataMapper: "creationTimestampEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Creation Date is the maximum creation date to be considered. If set, all records until this creation date are returned. Leave it empty to ignore this field." }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Modification Date", inputType: "date", dataMapper: "modificationTimestampStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Modification Date is the minimum modification date to be considered. If set, all records from this modification date are returned. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Modification Date", inputType: "date", dataMapper: "modificationTimestampEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Modification Date is the maximum modification date to be considered. If set, all records until this modification date are returned. Leave it empty to ignore this field." }));

    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "Start Completion Date", inputType: "date", dataMapper: "completedOnStart", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "Start Completion Date is the minimum completion date to be considered. If set, all records from this completion date are returned. Leave it empty to ignore this field." }));
    contentGrid.appendChild(ui.renderInput({ id: randomId(), label: "End Completion Date", inputType: "date", dataMapper: "completedOnEnd", dataType: "timestamp", value: "", mdColSpan: 6, helpText: "End Completion Date is the maximum completion date to be considered. If set, all records until this completion date are returned. Leave it empty to ignore this field." }));

    return grid
}

export function _renderPageFilters(context: "Records" | "Insights") {
    let primaryFiltersSection = renderPrimaryFiltersSection();
    let statusFiltersSection = renderStatusFiltersSection();
    let timeFiltersSection = renderTimeFiltersSection();
    let sortFiltersSection = ui.renderSortFiltersSection();

    // Attach specific sort key
    sortFiltersSection.contentGrid.prepend(ui.renderSelectForPartialEnum({
        id: randomId(),
        label: "Sort By",
        nameSplitsAt: "VENDOR_STREAM_SORT_KEY_",
        enumObject: VENDOR_STREAM_SORT_KEY,
        enumValues: [
            VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_CREATED_AT,
            VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_MODIFIED_AT,
            VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_COMPLETED_ON,
            VENDOR_STREAM_SORT_KEY.VENDOR_STREAM_SORT_KEY_TITLE,
        ],
        dataMapper: "sortKey",
        dataType: "number",
        value: "",
        mdColSpan: 12,
        helpText: "Sort the response with the selected field.",
        excludeZeroethValuedEnum: false,
    }));

    let buttonsDiv = document.createElement("div");
    buttonsDiv.className = `col-span-12 pl-4 float-right`;

    let getButtonId = randomId();
    let getButton = document.createElement("button");
    getButton.id = getButtonId;
    getButton.className = "btn btn-outline btn-success btn-md mr-4";
    getButton.innerText = `Get ${context}`;
    buttonsDiv.append(getButton);

    let resetButtonId = randomId();
    let resetButton = document.createElement("button");
    resetButton.id = resetButtonId;
    resetButton.className = "btn btn-outline btn-info btn-md mr-4";
    resetButton.innerText = "Reset";
    buttonsDiv.append(resetButton);

    const formId = randomId();

    return {
        html: `
            <div class="grid grid-cols-1 gap-6 mb-6">
                <div class="p-6 relative flex flex-col min-w-0 mb-4 lg:mb-0 break-words bg-[#f8f4f3] w-full shadow-lg rounded">
                    <div class="rounded-t mb-0 px-0 border-0">
                        ${ui.renderPageTitleSection({ title: "Search for Streams" }).outerHTML}
                    </div>
                    <form id="${formId}">
                        ${primaryFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${statusFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${timeFiltersSection.outerHTML}
                        <hr class="m-5">
                        ${sortFiltersSection.grid.outerHTML}
                        <hr class="m-5">
                        ${buttonsDiv.outerHTML}
                    </form>
                </div>
            </div>
        `,
        formId,
        resetButtonId,
        getButtonId
    } 
}