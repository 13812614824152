import { 
    quotationRequestFiltersMenu, 
    // quotationRequestInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleQuotationRequestFilters } from "./quotationrequests.filters";
import { handleQuotationRequestInsights } from "./quotationrequests.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(quotationRequestFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, quotationRequestFiltersMenu)) {
            handleQuotationRequestFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(quotationRequestInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, quotationRequestInsightsMenu)) {
    //         handleQuotationRequestInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
}