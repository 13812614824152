import { debitNoteFiltersMenu, debitNoteInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleDebitNoteFilters } from "./debitnotes.filters";
import { handleDebitNoteInsights } from "./debitnotes.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(debitNoteFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, debitNoteFiltersMenu)) {
            handleDebitNoteFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(debitNoteInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, debitNoteInsightsMenu)) {
            handleDebitNoteInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}