import { 
    quotationResponseFiltersMenu, 
    // quotationResponseInsightsMenu 
} from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleQuotationResponseFilters } from "./quotationresponses.filters";
import { handleQuotationResponseInsights } from "./quotationresponses.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(quotationResponseFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, quotationResponseFiltersMenu)) {
            handleQuotationResponseFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    // r.add(quotationResponseInsightsMenu.href, async (ctx) => {
    //     if (await interceptPage(ctx, quotationResponseInsightsMenu)) {
    //         handleQuotationResponseInsights(ctx);
    //     } else {
    //         handlePageForInvalidPermissions(ctx);
    //     }
    // });
}