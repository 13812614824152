import { purchasePaymentFiltersMenu, purchasePaymentInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handlePurchasePaymentFilters } from "./purchasepayments.filters";
import { handlePurchasePaymentInsights } from "./purchasepayments.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(purchasePaymentFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchasePaymentFiltersMenu)) {
            handlePurchasePaymentFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(purchasePaymentInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchasePaymentInsightsMenu)) {
            handlePurchasePaymentInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}