import { vendorInvoiceFiltersMenu, vendorInvoiceIndividualMenu, vendorInvoiceInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handleVendorInvoiceFilters } from "./vendorinvoices.filters";
import { handleVendorInvoiceInsights } from "./vendorinvoices.insights";
import { handleIndividualVendorInvoice } from "./individual";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(vendorInvoiceFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, vendorInvoiceFiltersMenu)) {
            handleVendorInvoiceFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(vendorInvoiceInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, vendorInvoiceInsightsMenu)) {
            handleVendorInvoiceInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(vendorInvoiceIndividualMenu.href, async (ctx) => {
        if (await interceptPage(ctx, vendorInvoiceFiltersMenu)) {
            handleIndividualVendorInvoice(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}