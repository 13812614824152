import { purchaseReturnFiltersMenu, purchaseReturnInsightsMenu } from "../../menus";
import { handlePageForInvalidPermissions, interceptPage } from "../../utilities";
import { Router, context } from "../../router";
import { handlePurchaseReturnFilters } from "./purchasereturns.filters";
import { handlePurchaseReturnInsights } from "./purchasereturns.insights";

/**All the routes of this module */
export function Routes(r: Router) {
    r.add(purchaseReturnFiltersMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchaseReturnFiltersMenu)) {
            handlePurchaseReturnFilters(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
    r.add(purchaseReturnInsightsMenu.href, async (ctx) => {
        if (await interceptPage(ctx, purchaseReturnInsightsMenu)) {
            handlePurchaseReturnInsights(ctx);
        } else {
            handlePageForInvalidPermissions(ctx);
        }
    });
}