
import { Router } from "./../router";
import * as quotationrequests from "./quotationrequests/routes";
import * as quotationresponses from "./quotationresponses/routes";
import * as purchaseenquiries from "./purchaseenquiries/routes";

/**All the routes of this module */
export function Routes(r: Router) {
    quotationrequests.Routes(r);
    quotationresponses.Routes(r);
    purchaseenquiries.Routes(r);
}